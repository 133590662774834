.top-bar {
    height: 70px;
    background-color: #191b31;
    color: white;
    border-bottom: 2px solid #1f2136;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    box-shadow: black 0 1px 10px;
}

.top-bar-left {
    flex: 2;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center
}

.top-bar-left-home {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    text-shadow: none;
    margin-left: 3vw;
    transition: all 0.3s;
}

.top-bar-left-home:hover {
    text-shadow: #FFF 1px 0 10px;
}

.top-bar-left-home img {
    height: 70%;
    width: auto;
}

.top-bar-left-home .title {
    margin: 0 1.5vw 0 1vw;
}

.top-bar-left .tab {
    margin: 0;
    font-weight: bold;
    font-size: 1em;
    background-color: inherit;
    height: 80%;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    border-bottom: 0 solid #191b31;
    border-top: 0 solid #191b31;
    box-sizing: border-box;
    text-shadow: none;
    text-align: center;
}

.top-bar-left .tab:hover {
    text-shadow: #FFF 1px 0 5px;
}

.top-bar-left .selected {
    background-color: #10346f;
    border-bottom: 5px solid #1f70ea;
    border-top: 5px solid #10346f;
    text-shadow: none;
}

.top-bar-left .selected:hover {
    text-shadow: none;
}

.top-bar-right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 3vw;
}


