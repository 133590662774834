

.auth-form {
    width: 100%;
    margin: 5vh auto;
    padding: 1vh 0 5vh;
    border-radius: 20px;
    background-color: #191b31;
    box-shadow: black 0 1px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 1.3em;
}

.auth-form h2 {
    text-align: center;
}



.auth-form form {
    width: 100%;
}

.auth-form .form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    margin: 1vh auto;
}

.auth-form .form-group label {
    margin-bottom: 8px;
    margin-top: 8px;
}


.auth-form .error-holder {
    height: 4vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-additional-infos {
    width: 100%;
    font-size: 1em;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


