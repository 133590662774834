.navbar {
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    color: #FFF;
    font-size: 0.7em;
    background-color: #191b31;
    width: 100%;
    box-shadow: black 0 -1px 10px;
}
