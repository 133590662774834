.host {
    background: linear-gradient(to right, #111024 0%, #201e4e 50%, #111024 100%);
}

.interlude-text {
    padding: 5vh 10%;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 0.9em;
}

.pause-text {
    padding: 10vh 10%;
}

.images {
    height: auto;
    padding: 0 10%;
    width: 80%;
}

.button-holders {
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none;
    font-size: 0.7em;
    text-transform: uppercase;
}

.button-holder {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.3s;
}

.button-holder:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: rgba(255, 255, 255, 0.1) 0 0 10px;
}

.button-holder img {
    width: 50%;
    height: auto;
}
