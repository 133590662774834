.main {
    font-family: 'teko', serif;
}

h3 {
    margin-left: 24px;
    font-size: 2em;
}

.stats-table {
    padding: 2% 0;
}

.stats-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.stats-key {
    flex: 4;
}

.stats-value {
    flex: 1;
    text-align: right;
}
