.board-game {
    min-height: 60vh;
}

.top-bar-anniv-2024 {
    background: linear-gradient(180deg, #10346f 0%, #191b31 100%);
    border-bottom: 2px solid #0a53be;
    display: block;
    position: fixed;
    top: 0;

    img {
        height: 40px;
        width: auto;
    }

    .player {
        flex: 1;
        height: 100%;
        font-size: 18px;
        display: flex;
        padding-left: 10px;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        color: white;
    }

    .budget {
        width: 25%;
        margin-right: 12px;
        height: 80%;
        background-color: black;
        border: 1px solid #0a53be;
        box-sizing: border-box;
        padding: 0 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .first-line {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .second-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .country {
            width: 47%;
            display: block;
            box-sizing: border-box;
            padding: 2.5%;
            margin: 1.5%;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.5);

            .country-name {
                font-size: 12px;
                text-align: center;
                padding-bottom: 2vh;
                img {
                    height: 12px;
                    width: auto;
                    margin-right: 6px;
                }
            }

            .button-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                .button {
                    height: 50px;
                    width: 60px;
                    border-radius: 8px;
                    display: flex;
                    font-size: 12px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: #191b31;

                    .icon {
                        color: orangered;
                    }

                    &.selected {
                        background: linear-gradient(180deg, #0a53be 0%, #191b31 100%);
                    }
                }

            }
        }
    }
}

.top-player-info-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2vh;
}

.top-player-info-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 2vh;

    .top-player-info-details-element {
        width: 30%;
        height: 20px;
        font-size: 18px;
        background-color: black;
        border: 2px solid #10346f;
        border-top: none;
        text-align: right;
        padding-right: 2vw;
        box-sizing: border-box;
    }
}

.top-player-info-bar-box {
    width: 100%;
    display: flex;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    box-sizing: border-box;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background: linear-gradient(180deg, #10346f 0%, #191b31 100%);

    img {
        height: 50px;
        width: auto;
        background-color: black;
        border-radius: 50%;
    }
}

.player-choice-button-containers {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.big-button {
    height: 50px;
    min-width: 70px;
    margin-right: 24px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: 2px solid #10346f;
    background-color: #191b31;
    padding: 0 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.country-button {
    height: 50px;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background: linear-gradient(180deg, #0a53be 0%, #191b31 100%);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    box-sizing: border-box;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.top-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.production-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;
}

.production-bar-box {
    width: 40%;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 25px;
    box-sizing: border-box;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background: linear-gradient(180deg, #10346f 0%, #191b31 100%);
}

.production-top-bar {
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
        height: 50px;
        width: auto;
    }
}

.production-order-bar {
    width: 100%;
    display: flex;
    height: 30px;
    justify-content: space-evenly;
    align-items: center;
}

.small-button {
    height: 28px;
    width: 28px;
    font-size: 20px;
    font-weight: bold;
    background-color: #0a53be;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        background-color: #434152;
    }
}

.distribution-bar {
    width: 100%;
    height: 48px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: linear-gradient(90deg, #191b31 0%, #10346f 50%, #191b31 100%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    margin: 2vh 0;

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.consumption-item {
    height: 60px;
    width: 48%;
    border-radius: 30px 0 0 30px;
    background: linear-gradient(180deg, #0a53be 0%, #191b31 100%);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.consumption-country {
    width: 100%;
    border-radius: 12px;
    border: 2px solid #10346f;
    background-color: #191b31;
    height: 100px;
    margin: 2vh 0;

    &.active {
        background: linear-gradient(180deg, #10346f 0%, #191b31 100%);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    }

    .consumption-country-first-row {
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .consumption-country-second-row {
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
    }
}

.buttonPanelRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;
}

.buttonPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 54px;
    min-width: 80px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 2px solid #0a53be;
    cursor: pointer;
}

.buttonPanelSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 54px;
    min-width: 80px;
    border-radius: 12px;
    background: linear-gradient(180deg, #0a53be 0%, #191b31 100%);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.invest-box {
    border-radius: 24px;
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    border: 2px solid #10346f;
    background-color: #1a1e21;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .invest-box-left {
        height: 100%;
        width: 60px;
        border-radius: 24px 0 0 24px;
        background: linear-gradient(180deg, #0a53be 0%, #10346f 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;

        img {
            width: 80%;
            height: auto;
        }
    }

    .invest-box-right {
        flex: 3;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;

        .invest-first-row {
            height: 30%;
            background-color: black;
            border-radius: 0 24px 0 0;
            border-bottom: 1px solid #10346f;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
        }

        .invest-row {
            width: 80%;
            padding: 0 10%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            margin-bottom: 4px;
        }
    }
}

.modal-graph {
    width: 90vw;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 125px 5vw;
    align-items: center;
    background-color: #191b31;
    border-radius: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 2vh 0;
}

.chart-holder {
    width: 85vw;
    height: 300px;
}

.income-box {
    border-radius: 24px;
    width: 100%;
    background-color: #1a1e21;
    margin-bottom: 24px;
    margin-top: 24px;
}

.income-box-header {
    height: 48px;
    width: 100%;
    border-radius: 24px 24px 0 0;
    background: linear-gradient(180deg, #10346f 0%, #191b31 100%);
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
        padding: 0 24px;
    }
}

.income-box-content {
    width: 100%;
    padding: 5px 5% 20px 5%;
    background-color: #1a1e21;
    border: 2px solid #191b31;
    border-radius: 0 0 24px 24px;
    box-sizing: border-box;
    border-top: none;
}

.income-box-line {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 4px;
}

.statistics-box {
    display: block;
    width: 100%;
    margin-bottom: 40px;
}

.statistics-box-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.party-box {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
}

.warning-event {
    color :orangered;
    margin: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .icon {
    }

    .message{
        flex: 1;
        height: 100%;
    }
}

.buyers-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;

    .buyers-header {
        height: 100px;
    }

    .buyers-content {
        height: 50px;
    }

    .buyers-first-cell {
        flex: 2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .buyers-cell {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.black-row {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-bottom: 5px;
}

.investment-box-title {
    font-size: 18px;
    margin-top: 3vh;
    margin-bottom: 1vh;
}

.political-top-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;
}

.party-button {
    width: 18%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 12px;
    background: #191b31;
    border: 4px solid #284f6e;
    box-sizing: border-box;

    img {
        height: 50%;
        width: auto;
    }

    &.selected {
        border-color: #0a53be;
    }

    &.investing {
        background: linear-gradient(180deg, #0a53be 0%, #191b31 100%);
    }
}
