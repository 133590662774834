.board-game-home-top-bar {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #191b31;
    border-bottom: 2px solid #1f2136;
    box-shadow: black 0 1px 10px;
}

.board-game-home-top-button {
    flex: 10;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    width: 100px;
    max-width: 25vw;
    margin: 0 5px;
    text-align: center;
    color: #FFF;
    box-sizing: border-box;
    transition: all 0.3s;
    cursor: auto;
}

.board-game-home-top-button-active {
    background-color: #10346f;
    border-bottom: 5px solid #1f70ea;
    border-top: 5px solid #10346f;
}

.board-game-home-top-button-inactive {
    background-color: #191b31;
    border-bottom: 5px solid #191b31;
    border-top: 5px solid #191b31;
    cursor: pointer;
}

.board-game-home-top-button-disabled {
    background-color: #434152;
    border-bottom: 5px solid #434152;
    border-top: 5px solid #434152;
    color: gray;
}

.card-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 12px;
}


.carousel-root, .carousel.carousel-slider,
.carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider,
.carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 100%;
}

.carousel .thumbs-wrapper {
    display: none;
}

.carousel .carousel-status {
    display: none;
}

.carousel.carousel-slider .control-arrow {
    color: white;
}

b {
    text-shadow: white 0 0 2px;
}

.board-game-build-action {
    height: 70px;
    width: 120%;
    margin-left: -13%;
    border-radius: 12px;
    border: 2px solid #10346f;
    background-color: #191b31;
    padding: 0 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.board-game-build-action-container {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
