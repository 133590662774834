.host {
    background: linear-gradient(to right, #111024 0%, #201e4e 50%, #111024 100%);
}

.interlude-text {
    padding: 1vh 10% 3vh;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 0.9em;
}
